import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
// import PropTypes from 'prop-types'

class Spacetable extends React.Component {
    cod_element = React.createRef();
    cod_segment = React.createRef();
    
    handleFieldChange = (e) => {
        this.props.updateFieldState(this.props.indexid, e.currentTarget.name, e.currentTarget.value);
    }
    handleConstruction = () => {
        alert('Under construction');
    }
    handleDeleteField = (e) =>{
        // console.log('handleDeleteField');
        // console.log(e);
    }
    handelSmFieldEdit = (e) =>{
        if(e.currentTarget.attributes.data_call){
            this.props.smFieldEdit(e.currentTarget.attributes.data_call.value);
        }else{
            alert('No data to change');
        }
        
    }
    handleSaveField = (e) =>{
        // console.log(e);
    }
    render(){
    return (
        <div className="row">
            <div className="col-lg-1 text-right">
                <label>&nbsp;</label>
                <button className="btn btn-ctm-inactive">
                    {parseFloat(this.props.indexid)+1}
                </button>
            </div>
            <div className="col-md-6 col-lg-2 col-6">
                <div className="form-group">
                    <fieldset disabled = { 
                    this.props.smEditSwitch.switch && this.props.smEditSwitch.id === this.props.currentId ? 
                    false:true }>
                        <label>Cod element</label>
                        <input type="text" 
                        ref = { this.cod_element } 
                        className="form-control" 
                        name="cod_element"
                        value = { this.props.cod_element }
                        onChange = { this.handleFieldChange }
                        autoComplete="off"
                        />
                    </fieldset>
                </div>
            </div>
            <div className="col-md-6 col-lg-2 col-6">
                <div className="form-group">
                    <fieldset disabled = { 
                    this.props.smEditSwitch.switch && this.props.smEditSwitch.id === this.props.currentId ? 
                    false:true }>
                        <label>Cod segment</label>
                        <input type="text" 
                        ref = { this.cod_segment } 
                        className="form-control"
                        name="cod_segment"
                        value = {this.props.cod_segment}
                        onChange = { this.handleFieldChange }
                        autoComplete="off"
                        />
                    </fieldset>
                </div>
            </div>
            <div className="col-md-12 col-lg-2 col-6">
                <div className="form-group">
                    <label>Utilizator</label>
                    <input type="text" 
                    className="form-control" 
                    value = {this.props.utilizator}
                    readOnly= {true}
                    />
                </div>
            </div>
            <div className="col-md-12 col-lg-3 col-6">
                <div className="form-group">
                    <label>Data și ora</label>
                    <input type="text"
                    className="form-control" 
                    value = {this.props.timestamp}
                    readOnly= {true}
                    />
                </div>
            </div>
            <div className="col-lg-2 col-md-12 text-center btns">
                <label>&nbsp;</label>
                <button className="btn btn-ctm mr-1" data_call={this.props.currentId} onClick={this.handelSmFieldEdit}>
                    <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className={this.props.smEditSwitch.smEdited.id === this.props.currentId? 
                    'btn btn-ctm mr-1 btnActive':'btn btn-ctm mr-1'} 
                    data_call={this.props.currentId} 
                    onClick={this.props.handleSaveField}
                    disabled = {this.props.smEditSwitch.switch && this.props.smEditSwitch.id === this.props.currentId ? false : true}
                    >
                    <FontAwesomeIcon icon={faCheck} />
                </button>
                <button className="btn btn-ctm mr-1" 
                    data_call={this.props.cod_element} 
                    data_id = {this.props.currentId} 
                    onClick={this.props.handleInfo}
                    >
                    <FontAwesomeIcon icon={faInfoCircle} data_call={this.props.cod_element}/>
                </button>
                <button className="btn btn-ctm" 
                    data_call={this.props.currentId} 
                    onClick={this.props.deleteField}
                    disabled = {this.props.smEditSwitch.switch && this.props.smEditSwitch.id === this.props.currentId ? false : true}
                    >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
                {this.props.currentProductName && this.props.infoPructId === this.props.cod_element?
                <button className="btn btn-ctm btn-block btn-name" onClick={this.props.handleInfo}>
                    {this.props.currentProductName}
                </button>
                :''}
            </div>
            <div className="col-lg-12 separator">&nbsp;</div>
        </div>
    )
}
}
Spacetable.propTypes = {

}

export default Spacetable
