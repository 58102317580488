import React, { Fragment } from 'react'

export const Uploader = (props) => {
    const bulkUploadRef = React.createRef();
    const bulkUpload = (e) => {
        e.preventDefault();
        let currentFile = bulkUploadRef.current.files[0];
        let formFile = new FormData(e.target)
        formFile.append("file", currentFile);
        formFile.append("user", props.currentUser);
        formFile.append("table", props.currentTable);
        props.axiosRequest(formFile, 'uploadFile', 'bulk');
        e.currentTarget.reset();
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col mb-2">
                    <h4 className="text-center mt-4">
                        Upload fisier excel/csv
                    </h4>
                </div>
            </div>
            <form onSubmit={ bulkUpload } className="row">
                <div className="col-lg-6 col-6">
                    <select defaultValue="0" name="selectCompanyId" className="form-control" onChange = {props.handleTableChange}>
                        <option value="0" disabled>Alege un tabel</option>
                        {Object.keys(props.companyTables).length>0?
                            Object.keys(props.companyTables).map( key => (
                                <option key={(key+1)} value={props.companyTables[key].table_name}>{props.companyTables[key].table_name.split('_').join(' ')}</option>
                            ))
                        :''}
                    </select>
                </div>
                <div className="col-lg-6 col-6 mb-2">
                    <div className="custom-file">
                        <input type="file" ref={ bulkUploadRef } className="custom-file-input" id="customFile"/>
                        <label className="custom-file-label" htmlFor="customFile">Alege Fișier</label>
                    </div>
                </div>
                <div className="col-12">
                    <input type="submit" value="Upload" className="btn btn-ctm btn-block mt-4" />
                </div>
            </form>
        </Fragment>
    )
}

export default Uploader;