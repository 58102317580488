import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const CreateTable = props => {
    const companyNameRef = React.createRef();
    const companyUserRef = React.createRef();
    return (
        <div className="row justify-content-center">
            <div className="col col-lg-8 card mt-3">
                <h5 className="text-center mt-4 mb-4">
                        Crează tabele pentru companie
                </h5>
                <form className="row justify-content-center" onSubmit={props.handleCreateTable} >
                    {props.userLevel>2?
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <select ref={companyNameRef} defaultValue="0" name="selectCompanyId" className="form-control" onChange = {props.handleTypeCtable}>
                                    <option value="0" disabled>Alege Companie</option>
                                    {Object.keys(props.allCompanies).map( key => (
                                    <option key={(key+1)} value={props.allCompanies[key].id}>{props.allCompanies[key].company_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    :''}
                    <div className= {props.userLevel>2?'col-md-6 col-lg-6':'col-md-12 col-lg-12'}>
                        <div className="form-group">
                            <input type="text" 
                            ref = {companyUserRef} 
                            className="form-control" 
                            id="newTableName" 
                            placeholder="Nume tabel" 
                            aria-describedby="userHelp"
                            autoComplete="off"
                            name="newTableName"
                            onChange = {props.handleTypeCtable}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                        <button className="btn btn-block btn-ctm mb-4 mt-2">
                            <FontAwesomeIcon icon={faPlus} />&nbsp;Înregistrează tabel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default CreateTable
