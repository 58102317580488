import React from 'react';
// import PropTypes from 'prop-types';
// import $ from 'jquery';
import axios from 'axios';

const Login = (props) => {
    const userRef = React.createRef();
    const passRef = React.createRef();

    
    // static propTypes = {
    //     userName: PropTypes.func
    // }
    
    const handleUserLogin = event => {
        event.preventDefault();
        const logUser = {
            email: userRef.current.value,
            password: passRef.current.value,
        }
        logInEndPoint (logUser);
    }
    
    const logInEndPoint = (logUser) =>{
        // const $this = this;
        const $props = props;
        axios.post('https://api.appdesign.cf?task=login&action=guest', logUser)
          .then(function (response) {
            let $re = response.data;
            // console.log($re.user.first_name);
            if($re.sc && $re.msg !== 'wrongpass'){
                $props.updateUserState({
                    first_name:$re.user.first_name, 
                    last_name:$re.user.last_name, 
                    uid:$re.user.uid,
                    email:$re.user.email,
                    level:$re.user.level,
                    company:$re.user.company
                });
            }else{
                $props.updateUserState({userError:$re.errormsg});
            }
            
          })
          .catch(function (error) {
            console.log(error);
          });
        
    }
        return (
            <div className="row justify-content-center" >
               <div className="col-md-8 col-lg-6">
                    <form onSubmit={handleUserLogin}>
                        <div className="form-group">
                            <input type="text" 
                            ref = {userRef} 
                            className="form-control" 
                            id="user" 
                            placeholder="Utilizator" 
                            aria-describedby="userHelp"
                            />
                        </div>
                        <div className="form-group">
                            <input type="password" 
                            ref = {passRef} 
                            className="form-control"  
                            placeholder="Parolă" 
                            id="password" 
                            />
                        </div>
                        <button type="submit" className="btn btn-ctm btn-block mt-4 mb-5">Intră în cont</button>
                    </form>
               </div>
            </div>
        )
}

export default Login
