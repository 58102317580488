import React, { Fragment } from 'react'

export const ProductUploader = (props) => {
    const bulkUploadRef = React.createRef();
    // const tabelsRef = React.createRef();
    const bulkUpload = (e) => {
        e.preventDefault();
        let currentFile = bulkUploadRef.current.files[0];
        let formFile = new FormData(e.target)
        formFile.append("file", currentFile);
        formFile.append("user", props.currentUser);
        props.axiosRequest(formFile, 'uploadProds', 'bulk_asoc');
        e.currentTarget.reset();
    }
    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col col-lg-8 mt-3 card">
                        <h5 className="text-center mt-4 mb-3">
                            Upload fisier excel/csv - produse si coduri
                        </h5>
                        <form onSubmit={ bulkUpload } className="row justify-content-center">
                            <div className="col-6">
                                <div className="form-group">
                                <select defaultValue="0" name="selectCompanyId" className="form-control" onChange = {props.handleTableChange}>
                                    <option value="0" disabled>Alege un tabel</option>
                                    {Object.keys(props.companyTables).length>0?
                                        Object.keys(props.companyTables).map( key => (
                                            <option key={(key+1)} value={props.companyTables[key].table_name}>{props.companyTables[key].table_name.split('_').join(' ')}</option>
                                        ))
                                    :''}
                                </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="custom-file">
                                    <div className="custom-file">
                                        <input type="file" ref={ bulkUploadRef } className="custom-file-input" id="customFile"/>
                                        <label className="custom-file-label" htmlFor="customFile">Alege Fișier</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <input type="submit" value="Upload" className="btn btn-ctm btn-block mt-2 mb-4" />
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-center">
                                    Template-uri .csv &amp; .xlsx pentru upload
                                </h5>
                                
                            </div>
                            <div className="col-12 mb-5 text-center">
                                <a className="btn btn-ctm mr-2" download href="https://api.appdesign.cf/samples/table_products_sample_csv.csv">Download CSV</a>
                                <a className="btn btn-ctm" download href="https://api.appdesign.cf/samples/table_products_sample_excel.xlsx">Download XLSX</a>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}

export default ProductUploader;