import React from 'react'

const EditAccount = props => {
    const editOldPassRef = React.createRef();
    const editPassRef = React.createRef();
    const editCheckPassRef = React.createRef();
    const firstNameRef = React.createRef();
    const lastNameRef = React.createRef();
    const handleNameChange = (e) => {
        const currentTarget = e.target.name;
        const currentTargetValue = e.target.value;
        props.handleEditAccount(currentTarget, currentTargetValue);
    }
    return (
            <div className="row justify-content-center">
                <div className="col col-lg-8 card mt-3">
                    <h5 className="text-center mt-4 mb-4">
                        Modifică date cont
                    </h5>
                    <form onSubmit={props.handleEditAccountSubmit} className="row justify-content-center" >
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="text" 
                                ref = {firstNameRef} 
                                className="form-control" 
                                id="edit_first_name" 
                                placeholder="Prenume" 
                                aria-describedby="userHelp"
                                autoComplete="off"
                                name="first_name"
                                value={props.editUser.first_name}
                                onChange = {handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="text" 
                                ref = {lastNameRef} 
                                className="form-control" 
                                id="edit_last_name" 
                                placeholder="Nume" 
                                aria-describedby="userHelp"
                                autoComplete="off"
                                name="last_name"
                                value={props.editUser.last_name}
                                onChange = {handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <label>Lasă câmpurile necompletate daca nu vrei să schimbi parola</label>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <input type="password" 
                                ref = {editOldPassRef} 
                                className="form-control"  
                                placeholder="Parolă veche" 
                                id="edit_old_password"
                                autoComplete="off"
                                name="edit_old_password" 
                                onChange = {handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="password" 
                                ref = {editPassRef} 
                                className="form-control"  
                                placeholder="Parolă" 
                                id="edit_password"
                                autoComplete="off"
                                name="edit_password"
                                onChange = {handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="password" 
                                ref = {editCheckPassRef} 
                                className="form-control"  
                                placeholder="Re-tastează parola" 
                                id="edit_retype_password"
                                autoComplete="off"
                                name="retype_password"
                                onChange = {handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="col mb-3">
                            <button className="btn btn-ctm btn-block">Înregistrează modificări</button>
                        </div>
                    </form>
                </div>
            </div>
    )
}

export default EditAccount
