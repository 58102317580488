import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react'
import App from './App';
import Update from './components/Update';
import NotFound from './components/NotFound';
import Account from './components/Account';

class Router extends React.Component{
   render(){
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={App}></Route>
                <Route path="/user/:uid" component={ Update }></Route>
                <Route path="/account" component={ Account }></Route>
                <Route component={ NotFound } wReload = { this.wReload }></Route>
            </Switch>
        </BrowserRouter>
    )
   }
}

export default Router
