import React from 'react'

const Pagination = props => {
    let pages = [];
    for (let i = 0; i < props.last_page; i++) {    
            pages.push(i);
        }
    return (
        <div className="row">
            <div className="col-lg-12 text-center mb-3 mt-3">
                {Object.keys(pages).map( key => (
                    parseFloat(props.smPagActive) === parseFloat(key)+1?
                    <button className="btn btn-ctm mr-2 mb-2 btnActive" data_call={parseFloat(key)+1} onClick={props.handlePagination} key= {key}>{parseFloat(key)+1}</button>
                    :
                    <button className="btn btn-ctm mr-2 mb-2" data_call={parseFloat(key)+1} onClick={props.handlePagination} key= {key}>{parseFloat(key)+1}</button>
                ))}
            </div>
        </div>
    )
}

export default Pagination
