import React from 'react';
// import PropTypes from 'prop-types';
import AddAccount from './AddAccount';
import AssignTable from './AssignTable';
import EditAccount from './EditAccount';
import CreateTable from './CreateTable';
import AddCompany from './AddCompany'
import Header from './Header'
import Modal from './Modal'
import ProductUploader from './ProductUploader';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

class Account extends React.Component{
    state = {
        userName:{
            uid:'',
            first_name:'',
            last_name:'',
            email:'',
            level:''
        },
        modal:{
            open:false,
            modalTitle:'',
            modalMessage:'',
            modalBtn1:'',
            modalBtn2:'',
            deleteField:false
        },
        newUser:{
            first_name:'',
            last_name:'',
            user:'',
            password:'',
            retype_password:'',
            account_lvl:''
        },
        edit:{
            first_name:'',
            last_name:'',
            edit_old_password:'',
            edit_password:'',
            retype_password:'',
        },
        companies:{
            all:[],
            addNew:'',
            newTableName:'',
            selectCompanyId:''
        },
        currentUsers:{
            all:[],
            selected:{
                name:'',
                value:''
            },
            found:{}
        },
        attachUser:{},
        currentTable:'',
        companyTables:[]

    } 
    handleUserCreate = (e) => {
        e.preventDefault();
        let userInput = {
            current_user:this.state.userName.email,
            first_name:this.state.newUser.first_name,
            last_name:this.state.newUser.last_name,
            email:this.state.newUser.user,
            level:this.state.newUser.account_lvl,
            password:this.state.newUser.password,
            retype_password:this.state.newUser.retype_password
        };
        this.axiosRequest(userInput, 'save', 'guest');
        e.currentTarget.reset();
    }
    handleTypeCompany = (e) => {
        this.companyStateAction('addNew', e.currentTarget.value)
    }
    handleTypeCtable = (e) => {
        this.companyStateAction(e.currentTarget.name, e.currentTarget.value);
    }

    handleSubmitCompany = (e) => {
        e.preventDefault();
        const userInput = {
            company_name: this.state.companies.addNew,
            user:this.state.userName.email
        }
        this.axiosRequest(userInput, 'createCompany', 'name');
        e.currentTarget.reset();
    }
    companyStateAction(key, value){
        const companies = {...this.state.companies};
        companies[key] = value;
        this.setState({companies});
    }
    usersStateAction(key, value){
        const currentUsers = {...this.state.currentUsers};
        currentUsers[key] = value;
        this.setState({currentUsers});
    }
    handleSubmitNewUsers = (e) =>{
        e.preventDefault();
        let company = (parseFloat(this.state.userName.level) < 3) ? this.state.userName.company:this.state.companies.selectCompanyId;
        // console.log(company);
        if(!company){
            this.modalOpenMessage(
                'Eroare',
                'Nu ai selectat compania',
                'Închide'
            );
            return;
        }
        if(Object.keys(this.state.attachUser).length === 0){
            this.modalOpenMessage(
                'Eroare',
                'Nu ai adaugat utilizator',
                'Închide'
            );
            return;
        }
        let formdata = [JSON.stringify(this.state.attachUser), this.state.userName.email, company];
        this.axiosRequest(formdata,'attachUsers', 'all');
        let attachUser = {...this.state.attachUser};
        attachUser = {};
        this.setState({attachUser});
    }
    addNewUser = (e) => {
        const uid = e.currentTarget.attributes.data_uid.value;
        const email = e.currentTarget.attributes.data_email.value;
        const attachUser = {...this.state.attachUser};
        attachUser[uid] = email;
        // attachUser.email = email;
        this.setState({attachUser});

    }
    removeAttached = (e) => {
        const uid = e.currentTarget.attributes.data_uid.value;
        const attachUser = {...this.state.attachUser};
        delete attachUser[uid];
        // attachUser.email = email;
        this.setState({attachUser});
    }
    handleTypeCompanyUser = (e) => {
        const $this = this;
        if(e.currentTarget.value.length < 2){
            $this.axiosRequest({utilizator:$this.state.userName.email},'getUsers', 'all');           
        }
        var returns = this.findMatches(e.currentTarget.value);
        const currentUsers = {...this.state.currentUsers};
        currentUsers.found = returns;
        this.setState({currentUsers});
    }
    findMatches(wordToMatch) {
        return this.state.currentUsers.all.filter(user => {
          const regex = new RegExp(wordToMatch, 'gi');
          return user.email.match(regex)
        });
    }
    handleCreateTable = (e) => {
        e.preventDefault();
        this.axiosRequest({
            table_name:this.state.companies.newTableName, 
            company_id:this.state.userName.level === 2?this.state.userName.company:this.state.companies.selectCompanyId,
            utilizator:this.state.userName.email
        },
        'createSpaces', 'table');
    }
    modalOpenMessage = (title, content, btn1, btn2) => {
        const modal = {
            open:true,
            modalTitle:title,
            modalMessage:content,
            modalBtn1:btn1?btn1:'',
            modalBtn2:btn2?btn2:'',
            deleteField:false
        }
        this.modalAction(modal);
    }
    axiosRequest = (userInput, task, action) => {
        const $this = this;  
        axios.post('https://api.appdesign.cf?task='+task+'&action='+action, userInput, {headers: { 'Content-Type': 'multipart/form-data' }})
            .then(function (response) {
                let $re = response.data;
                if($re.sc){
                    if(task === 'save'){
                        // handle new account creation message
                        if($re.msg === 'saved'){        
                            $this.modalOpenMessage(
                                'Utilzator salvat',
                                'Mulțumim pentru înregistrarea noului utilizator',
                                'Închide'
                            );
                        }else if ($re.sc === true && $re.msg === 'existent'){
                            $this.modalOpenMessage(
                                'Eroare',
                                'Utilizatorul adaugat exista deja in baza de date',
                                'Închide'
                            );
                        }
                    }
                    if(task === 'editAccount'){
                        if($re.msg === true){
                            $this.modalOpenMessage(
                                'Modificat',
                                'Contul tău a fost modificat',
                                'Închide'
                            );
                            localStorage.setItem('userName', JSON.stringify($this.state.userName));
                        }else{
                            $this.modalOpenMessage(
                                'Eroare',
                                $re.msg,
                                'Închide'
                            );
                        }
                    }
                    if(task === 'createCompany'){
                        if($re.msg === true){
                            $this.modalOpenMessage(
                                'Înregistrat',
                                'Compania a fost adăugată',
                                'Închide'
                            );
                            const currentUserId = $this.state.userName.email;
                            setTimeout(function(){
                                $this.axiosRequest({email:currentUserId}, 'getCompanies', 'all');
                            }, 700);
                        }else{
                            $this.modalOpenMessage(
                                'Eroare',
                                $re.msg,
                                'Închide'
                            ); 
                        }
                    }
                    if(task === 'getCompanies'){
                        $this.companyStateAction('all', $re.msg);
                    }
                    if(task === 'createSpaces'){
                        if($re.msg === true){
                            $this.modalOpenMessage(
                                'Înregistrat',
                                'Tabelul a fost creat',
                                'Închide'
                            );
                        }else{
                            $this.modalOpenMessage(
                                'Eroare',
                                $re.msg,
                                'Închide'
                            );
                        }
                    }
                    if(task === 'getUsers'){
                        if (typeof($re.msg) === "object"){
                            const currentUsers = {...$this.state.currentUsers};
                            currentUsers.all = $re.msg;
                            $this.setState({currentUsers});
                            return $re.msg;
                        }else{
                            $this.modalOpenMessage(
                                'Eroare',
                                $re.msg,
                                'Închide'
                            );
                        }                       
                    }
                    if (task === 'attachUsers'){
                        if($re.msg === true){
                            $this.modalOpenMessage(
                                'Înregistrat',
                                'Utilizatorii au fost atașați',
                                'Închide'
                            );
                        }else{
                            $this.modalOpenMessage(
                                'Eroare',
                                $re.msg,
                                'Închide'
                            );
                        }
                    }
                    if (task === 'getTablesByCompany'){
                        $this.updateCompanyTables($re.msg);
                    }
                    if(task === 'getLvl3Tables'){
                        $this.updateCompanyTables($re.msg);
                    } 
                }else{
                    console.log(response.data, 'axios call failure');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    updateCompanyTables(data){
        let companyTables = this.state.companyTables;
        companyTables = data;
        this.setState({companyTables});
        if(this.state.userName.level !== '3'){
            this.updateCurrentTable(data[0]);
        }
    }
    updateCurrentTable(data){
        let currentTable = this.state.currentTable;
        currentTable = data.table_name;
        this.setState({currentTable});
    }
    goHome = () => {
        const home = window.location.href.split('/')[2];
        window.location.href = '//'+home
    }
    componentDidMount = () => {
        const localStorageRef = localStorage;
        const thisState = {...this.state};
        const ls = JSON.parse(localStorage.getItem('userName'));
        if (localStorageRef.length>0 && localStorage.getItem('userName')){
            thisState.userName = ls;
            this.setState({userName:thisState.userName});
        }else{
           this.goHome(); 
        }
        const currentUserId = this.state.userName.email ? this.state.userName.email:ls.email;
        this.axiosRequest({email:currentUserId}, 'getCompanies', 'all');
        let company = this.state.userName.company?this.state.userName.company:ls.company;
        let level = this.state.userName.level?this.state.userName.level:ls.level;
        if(parseInt(level) !== 3){
            this.axiosRequest({'company':company}, 'getTablesByCompany', 'all');
        }else{
            this.axiosRequest({'level':this.state.userName.id}, 'getLvl3Tables', 'all');
        }
        
    }
    modalClose = () => {
        this.modalAction({
            open:false,
            modalTitle:'',
            modalMessage:'',
            modalBtn1:'',
            modalBtn2:'',
            deleteField:false
        });
    }
    modalAction(modal){
        const thisState = {...this.state};
        thisState.modal = modal;
        this.setState({modal});
    }
    // modalAction(modal){
    //     const thisState = {...this.state};
    //     thisState.modal = modal;
    //     this.setState({modal});
    // }
    setNewUser = (key, value) => {
        const newUser = {...this.state.newUser};
        newUser[key] = value;
        this.setState({newUser});
    }
    handleEditAccount = (key, value) =>{
        const edit = {...this.state.edit};
        const userName = {...this.state.userName};
        edit[key] = value;
        userName[key] = value;
        this.setState({edit, userName});
    }
    handleEditAccountSubmit = (e) => {
        e.preventDefault();
        let userInput = {
            first_name:this.state.edit.first_name? this.state.edit.first_name:this.state.userName.first_name,
            last_name:this.state.edit.last_name? this.state.edit.last_name: this.state.userName.last_name,
            email: this.state.userName.email,
            uid:this.state.userName.uid,
            old_password:this.state.edit.edit_old_password,
            password:this.state.edit.edit_password,
            retype_password:this.state.edit.retype_password
        };
        this.axiosRequest(userInput, 'editAccount', 'guest');
    }
   render(){
    return (
        <div className='container min-height-100'>
            <Header userName = {this.state.userName ? this.state.userName : false} />
            <div className="row">
                <div className="text-center faLg col">
                    <a className="btn btn-ctm mb-3 mt-3" href={'/user/'+this.state.userName.uid}>
                        <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                        <span>Back</span> 
                    </a>
                </div>
            </div>
            <AddAccount 
                handleUserCreate = {this.handleUserCreate}
                setNewUser = {this.setNewUser}
                userName = {this.state.userName.level}
            />
            <EditAccount 
                handleEditAccount = {this.handleEditAccount}
                handleEditAccountSubmit = {this.handleEditAccountSubmit}
                editUser = {this.state.userName}
            />
            {this.state.userName.level > 2?
                <AddCompany handleSubmitCompany ={this.handleSubmitCompany} handleTypeCompany = {this.handleTypeCompany}/>
            :''}
            {this.state.userName.level > 1?
            <CreateTable 
                userLevel = {this.state.userName.level} 
                allCompanies = {this.state.companies.all} 
                handleCreateTable = {this.handleCreateTable}
                handleTypeCtable = {this.handleTypeCtable}
                />
            :''}
            {this.state.userName.level > 1?
            <AssignTable 
                handleTypeCompanyUser = {this.handleTypeCompanyUser}
                allCompanies = {this.state.companies.all} 
                userLevel = {this.state.userName.level} 
                found = {this.state.currentUsers.found}
                addNewUser = {this.addNewUser}
                attachUser = {this.state.attachUser}
                removeAttached = {this.removeAttached}
                handleSubmitNewUsers = {this.handleSubmitNewUsers}
                handleTypeCtable = {this.handleTypeCtable}
                />
            :''}
            <ProductUploader 
                    companyTables = {this.state.companyTables}
                    currentTable = {this.state.currentTable} 
                    handleTableChange = {this.handleTableChange}
                    axiosRequest = {this.axiosRequest}
                    currentUser = {this.state.userName.email}
                />
            <div className="separator row mb-4">&nbsp;</div>
            <Modal modalClose={this.modalClose} modal = {this.state.modal}/>
        </div>
    );
   }
}

// Account.propTypes = {

// }

export default Account
