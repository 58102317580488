import React from 'react';
import Header from './Header';
import Spacetable from './Spacetable';
import axios from 'axios';
import Uploader from './Uploader';
import Pagination from './Pagination';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'

class Update extends React.Component{
    state = {
        userName:{
          uid:'',
          first_name:'',
          last_name:'',
          email:'',
          level:'',
          company:''
        },
        smContainer:[],
        smContainerError:'',
        smPagination:'',
        smPagActive:1,
        smEditSwitch:{
            switch:false,
            id:'',
            smEdited:{}
        },
        uploadContainer:{
            msg:'',
            url:''
        },
        currentTable:'space_table_1',
        currentProductName:'',
        infoPructId:'',
        modal:{
            open:false,
            modalTitle:'',
            modalMessage:'',
            modalBtn1:'',
            modalBtn2:'',
            deleteField:false
        },
        companyTables:[]
      }
    codElement = React.createRef();
    codSegment = React.createRef();
    companyTablesRef = React.createRef();
    updateFieldState = (index, field, data) => {
        const smContainer = {...this.state.smContainer};
        const smEditSwitch = {...this.state.smEditSwitch};
        smContainer[index][field] = data;
        smEditSwitch.smEdited = smContainer[index];
        this.setState({smContainer, smEditSwitch});
    }
    smFieldEdit = (e) =>{
        const smEditSwitch = {...this.state.smEditSwitch};
        smEditSwitch.switch = true;
        smEditSwitch.id = e;
        smEditSwitch.smEdited = {};
        this.setState({smEditSwitch});
    }
    handleSaveField = (e) =>{
        let formData = {
            'table':this.state.currentTable,
            'field':this.state.smEditSwitch.smEdited,
            'utilizator':this.state.userName.email, 
        }
        this.axiosRequest(formData, 
            'updateExistingSpace', 
            'entry');
        this.axiosRequest({'utilizator':this.state.userName.email, 'page':this.state.smPagActive, 'table':this.state.currentTable}, 'getSpaceData', 'select');
        const smEditSwitch = {...this.state.smEditSwitch};
        smEditSwitch.switch = false;
        smEditSwitch.id = null;
        smEditSwitch.smEdited = {};
        this.setState({smEditSwitch});
    }
    handleInfo = (e) => {
        if(e.currentTarget.attributes.data_call !== undefined){
            const $this = this;
            const thisState = {...this.state};
            thisState.infoPructId = e.currentTarget.attributes.data_call.value;
            this.setState({infoPructId:e.currentTarget.attributes.data_call.value});
            this.axiosRequest({prod_id:parseFloat(e.currentTarget.attributes.data_call.value), table:$this.state.currentTable}, 'getSpaceElementById', 'element');
            setTimeout(function(){
                $this.axiosRequest({'utilizator':$this.state.userName.email, 'page':$this.state.smPagActive, 'table':$this.state.currentTable}, 'getSpaceData', 'select');
            }, 700);
        }else{
            const thisState = {...this.state};
            thisState.currentProductName = '';
            this.setState({currentProductName:''});
        }
    }
    handleDeleteField = (e) => {
        const $this = this;
        let dataCallValue = '';
        let dataCall = '';
        if (typeof(e) === 'object'){
            dataCall = e.currentTarget.attributes.data_call;
            dataCallValue = dataCall.value;
        }else{
            dataCall = true;
            dataCallValue = $this.state.modal.deleteField;
        }
        if(dataCall){
            if($this.state.modal.deleteField){
                $this.axiosRequest({prod_id:parseFloat(dataCallValue), table:$this.state.currentTable}, 'deleteFieldById', 'element');
                setTimeout(function(){
                    $this.axiosRequest({'utilizator':$this.state.userName.email, 'page':$this.state.smPagActive, 'table':$this.state.currentTable}, 'getSpaceData', 'select');
                    $this.modalAction({
                        open:false,
                        modalTitle:'',
                        modalMessage:'',
                        modalBtn1:'',
                        modalBtn2:'',
                        deleteField:false
                    });
                }, 700);
            }else{
                const modal = {
                    open:true,
                    modalTitle:'Șterge câmpul',
                    modalMessage:'Ești sigur că vrei să ștergi câmpul?',
                    modalBtn1:'Nu',
                    modalBtn2:'Da',
                    deleteField: dataCallValue
                }
                $this.modalAction(modal);
            }
        }
    }
    modalClose = (data) => {
        const $this = this;
        if(data.target.innerHTML === 'Da'){
            setTimeout(function(){
                $this.handleDeleteField();
            }, 500);
        }else{
            this.modalAction({
                    open:false,
                    modalTitle:'',
                    modalMessage:'',
                    modalBtn1:'',
                    modalBtn2:'',
                    deleteField:false
            });
        }
    }
    modalAction(modal){
        const thisState = {...this.state};
        thisState.modal = modal;
        this.setState({modal});
    }
    updateCurrentProductName(data){
        const thisState = {...this.state};
        let msg ='';
        if(data.msg){
            msg = data.msg['0'];
        }
        if(msg){
            thisState.currentProductName = msg['prod_name'];
            this.setState({currentProductName:msg['prod_name']});
        }else{
            thisState.currentProductName = 'Lipsă nume în tabel';
            this.setState({currentProductName:'Lipsă nume în tabel'});
        }
    }
    handleLogout = (e) => {
        e.preventDefault();
        const thisState = {...this.state.userName};
        localStorage.setItem('userName', '');
        thisState.userName = {};
        this.setState({userName:thisState.userName});
        this.goHome();
      }
    handlePagination = (e) => {
        const thisState = {...this.state};
        thisState.smPagActive = e.target.attributes.data_call.value;
        this.axiosRequest({
            'utilizator':this.state.userName.email, 
            'page':e.target.attributes.data_call.value,
            'table':this.state.currentTable
        }, 
            'getSpaceData', 
            'select');
        this.setState({smPagActive:thisState.smPagActive});
    }
    updateUploadContainer = (data) =>{
        const thisState = {...this.state};
        thisState.updatedContainer = {msg:data.msg, url:data.url};
        this.setState({uploadContainer:thisState.updatedContainer});
        this.axiosRequest({'utilizator':this.state.userName.email, 'table':this.state.currentTable}, 'getSpaceData', 'select');
    }
    updateSmContainer = (smContainer) => {
        const thisState = {...this.state};
        if(typeof(smContainer.msg) !== 'string'){
            thisState.smContainer = smContainer.msg;
            thisState.smPagination = smContainer.last_page;
        }else{
            thisState.smContainer = '';
            thisState.smPagination = '';
        }
        
        this.setState({smContainer:thisState.smContainer, smPagination:thisState.smPagination});
    }
    updateSmContainerError = (smContainerError) => {
        const thisState = {...this.state};
        thisState.smContainerError = smContainerError;
        this.setState({smContainerError:thisState.smContainerError});
    }
    axiosRequest = (userInput, task, action) => {
        const $this = this;  
        axios.post('https://api.appdesign.cf?task='+task+'&action='+action, userInput, {headers: { 'Content-Type': 'multipart/form-data' }})
        .then(function (response) {
            let $re = response.data;
            if($re.sc){
                if(task === 'updateSpace' && $re.msg !== true){
                    $this.updateSmContainerError($re.msg);
                }else if(task === 'updateSpace' && $re.msg === true){
                    $this.updateSmContainerError(false);
                }
                if(task === 'getSpaceData'){
                    if(typeof($re) !== 'string'){
                        $this.updateSmContainer($re);
                    }
                    
                }
                if(task === 'uploadFile'){
                    $this.updateUploadContainer($re);
                }
                if(task === 'getSpaceElementById'){
                    $this.updateCurrentProductName($re);
                }
                if (task === 'getTablesByCompany'){
                    $this.updateCompanyTables($re.msg);
                }
                if(task === 'getLvl3Tables'){
                    $this.updateCompanyTables($re.msg);
                }
                if(task === "exportTable"){
                    let url = 'https://api.appdesign.cf/'+$re.downloadLink;
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'https://api.appdesign.cf/'+$re.downloadLink;
                    a.click();
                }
            }else{
                console.log(response.data, 'axios call failure');
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
   
    handleUserInput = (e) =>{
        e.preventDefault();
        const $this = this;
        const userInput = {
            utilizator: this.state.userName.email,
            cod_element: this.codElement.current.value,
            cod_segment: this.codSegment.current.value,
            table:this.state.currentTable
        };
        this.axiosRequest(userInput, 'updateSpace', 'new');
       setTimeout(function(){
        $this.axiosRequest({
            'utilizator':$this.state.userName.email, 
            'page':$this.state.smPagActive, 
            'table':$this.state.currentTable}, 
            'getSpaceData', 'select');
       }, 700);
        e.currentTarget.reset();
        
    }
    goHome = () => {
        const home = window.location.href.split('/')[2];
        window.location.href = '//'+home
    }
 
    updateCompanyTables(data){
        let companyTables = this.state.companyTables;
        companyTables = data;
        this.setState({companyTables});
        if(this.state.userName.level !== '3'){
            this.updateCurrentTable(data[0]);
        }
    }
    updateCurrentTable(data){
        let currentTable = this.state.currentTable;
        currentTable = data.table_name;
        this.setState({currentTable});
        this.axiosRequest({
            'utilizator':this.state.userName.email, 
            'page':this.state.smPagActive, 
            'table':this.state.currentTable}, 
            'getSpaceData', 'select');
    }
    exportTable = () => {
        this.axiosRequest({'user':this.state.userName.email, 'table':this.state.currentTable}, 'exportTable', 'data');
    }
    componentDidMount(){
        const localStorageRef = localStorage;
        const thisState = {...this.state};
        const lsUser = JSON.parse(localStorage.getItem('userName'));
        if (localStorageRef.length>0 && localStorage.getItem('userName')){
            thisState.userName = JSON.parse(localStorage.getItem('userName'));
            this.setState({userName:thisState.userName});
        }else{
           this.goHome(); 
        }
        this.axiosRequest({'utilizator':thisState.userName.email, 'page':this.state.smPagActive, 'table':this.state.currentTable}, 'getSpaceData', 'select');
        let company = this.state.userName.company?this.state.userName.company:lsUser.company;
        let level = this.state.userName.level?this.state.userName.level:lsUser.level;
        if(parseInt(level) !== 3){
            this.axiosRequest({'company':company}, 'getTablesByCompany', 'all');
        }else{
            this.axiosRequest({'level':this.state.userName.id}, 'getLvl3Tables', 'all');
        }
        
    }
    handleTableChange = e =>{
        const thisState = {...this.state};
        thisState.currentTable = e.currentTarget.value;
        this.setState({currentTable:e.currentTarget.value});
        this.axiosRequest({'utilizator':this.state.userName.email, 'page':this.state.smPagActive, 'table':e.currentTarget.value}, 'getSpaceData', 'select');
    }
    render(){
        return(
            <div className="container mt-4">
                <Header 
                    userName = {this.state.userName ? this.state.userName : false}
                />                
                <div className="row">
                    <div className="col mb-2">
                        <div className="text-center faLg">
                            <button className="btn btn-ctm mb-4" onClick = { this.handleLogout }>
                                <span>Logout</span> &nbsp;
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </button>
                            <a className="ml-2 btn btn-ctm mb-4" href='/account'>
                                <FontAwesomeIcon icon={faUserCircle} className='mr-2'/>
                                <span>Management cont</span> &nbsp;
                            </a>
                        </div>
                        
                        <h4 className="text-center mt-2">
                            Adaugă intrare nouă
                        </h4>
                    </div>
                </div>
                <div className="row justify-content-center" >
                    <div className="col-lg-12">
                        <form onSubmit={this.handleUserInput}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" 
                                        ref = {this.codElement} 
                                        className="form-control" 
                                        id="codElement" 
                                        placeholder="Cod element" 
                                        aria-describedby="userHelp"
                                        autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" 
                                        ref = {this.codSegment} 
                                        className="form-control" 
                                        id="codSegment" 
                                        placeholder="Cod segment" 
                                        aria-describedby="userHelp"
                                        autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-ctm btn-block mt-2 mb-2">Salvează</button>
                        </form>
                    </div>
                </div>
                { this.state.smContainerError ?
                    <div className="row">
                        <div className="col mb-2">
                            <p className="text-center">
                                {this.state.smContainerError}
                            </p>
                        </div>
                    </div>
                :''}
                <Uploader 
                    currentUser = {this.state.userName.email} 
                    axiosRequest = {this.axiosRequest}
                    companyTables = {this.state.companyTables}
                    currentTable = {this.state.currentTable} 
                    handleTableChange = {this.handleTableChange}
                    />
                { this.state.uploadContainer.msg ?
                    <div className="row">
                        <div className="col mb-4">
                            <p className="text-center mt-2">
                                {this.state.uploadContainer.msg}
                            </p>
                        </div>
                    </div>
                :''}
                <div className="row">
                    <div className="col-12 mb-2">
                        <h5 className="text-center mt-3">
                            Template-uri .csv &amp; .xlsx pentru upload
                        </h5>
                        
                    </div>
                    <div className="col-12 mb-2 text-center">
                        <a className="btn btn-ctm mr-2" download href="https://api.appdesign.cf/samples/table_sample_csv.csv">Download CSV</a>
                        <a className="btn btn-ctm" download href="https://api.appdesign.cf/samples/table_sample_excel.xlsx">Download XLSX</a>
                    </div>
                    <div className="col-12 mb-2  mt-2 text-center">
                        <hr className="separator mt-3"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-4">
                        <h4 className="text-center mt-2 mb-5">
                            Listare
                        </h4>
                        <hr className="separator"/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row justify-content-center align-item-center">
                            {this.state.userName.company?
                                <div className={this.state.userName.level > 2? "col-lg-5 d-flex align-items-center":"col-lg-7 d-flex align-items-center"}>
                                    <h4 className="text-center mt-2 text-capitalize">
                                       Companie: {this.state.userName.company.split('_').join(' ')}
                                    </h4>
                                </div>
                            :''}
                            <div className={this.state.userName.level > 2? "col-lg-4 d-flex align-items-center":"col-lg-5 d-flex align-items-center"}>
                                <select ref={this.companyTablesRef} defaultValue={this.state.currentTable} name="selectCompanyId" className="form-control" onChange = {this.handleTableChange}>                
                                    {Object.keys(this.state.companyTables).length>0?
                                        Object.keys(this.state.companyTables).map( key => (
                                            <option key={(key+1)} value={this.state.companyTables[key].table_name}>{this.state.companyTables[key].table_name.split('_').join(' ')}</option>
                                        ))
                                    :''}
                                </select>
                            </div>
                            {this.state.userName.level > 2 ?
                                <div className="col-lg-3 d-flex align-items-center">
                                    <h4 className="text-center mt-2 text-capitalize">
                                    <button type="button" className="btn btn-ctm" onClick={this.exportTable}>Exportă tabel</button>
                                    </h4>
                                </div>
                            :''}
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <hr className="separator"/>
                    </div>
                </div>
                <div className="spaceTable">
                    {Object.keys(this.state.smContainer).map( key => (
                    <Spacetable indexid     = { key }
                                currentId   = { this.state.smContainer[key].id }
                                key         = { key+1 }
                                cod_element = { this.state.smContainer[key].cod_element } 
                                cod_segment = { this.state.smContainer[key].cod_segment }
                                utilizator  = { this.state.smContainer[key].utilizator }
                                timestamp   = { this.state.smContainer[key].timestamp }
                                updateFieldState = {this.updateFieldState}
                                smFieldEdit = {this.smFieldEdit}
                                smEditSwitch = {this.state.smEditSwitch}
                                handleSaveField = {this.handleSaveField}
                                handleInfo = {this.handleInfo}
                                currentProductName = {this.state.currentProductName}
                                infoPructId = {this.state.infoPructId}
                                deleteField = {this.handleDeleteField}
                                />
                    ))}                    
                </div>
                {this.state.smPagination>1?
                <Pagination 
                    handlePagination = {this.handlePagination} 
                    last_page = {this.state.smPagination}
                    smPagActive = {this.state.smPagActive}
                />
                :''}
                <Modal modalClose={this.modalClose} modal = {this.state.modal}/>
            </div>
            
        )
    }
}

Update.propTypes = {

}

export default Update
