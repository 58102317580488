import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
// import PropTypes from 'prop-types'
class AssignTable extends React.Component{
    companyUserRef = React.createRef();
    tablesRef = React.createRef();
    render(){
        return (
            <div className="row justify-content-center">
                <div className="col col-lg-8 card mt-3">
                    <h5 className="text-center mt-4 mb-3">
                            Atribuie utilizatori companiei
                    </h5>
                    <form onSubmit={this.props.handleSubmitNewUsers} className="row justify-content-center mb-3" >
                        {this.props.userLevel>2?
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <select ref={this.tablesRef} defaultValue="0" name="selectCompanyId" className="form-control" onChange = {this.props.handleTypeCtable}>
                                        <option value="0" disabled>Alege Companie</option>
                                        {Object.keys(this.props.allCompanies).map( key => (
                                        <option key={(key+1)} value={this.props.allCompanies[key].company_name}>{this.props.allCompanies[key].company_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        :''}
                        <div className= {this.props.userLevel>2?'col-md-6 col-lg-6':'col-md-12 col-lg-12'}>
                            <div className="form-group">
                                <input type="text"
                                data-toggle="dropdown" 
                                ref = {this.companyUserRef} 
                                className="form-control dropdown-toggle" 
                                id="user_assign" 
                                placeholder="Utilizator companie" 
                                aria-describedby="userHelp"
                                autoComplete="off"
                                name="company_user"
                                onChange = {this.props.handleTypeCompanyUser}
                                />
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {this.props.found.length?
                                        Object.keys(this.props.found).map( key => (
                                            <button key={key+1}
                                                    type="button"
                                                    className="dropdown-item" 
                                                    data_uid = {this.props.found[key].id}
                                                    data_email = {this.props.found[key].email}
                                                    onClick={this.props.addNewUser}>
                                                {this.props.found[key].email}
                                            </button>
                                        ))
                                    :
                                        <button type="button" className="dropdown-item" disabled>Tasteză numele utilizatorului</button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {this.props.attachUser? */}
                        <div className="col-md-12 col-lg-12 mb-1">
                            { Object.keys(this.props.attachUser).map( key => (
                            <button key = {parseFloat(key)+1}
                                    type="button"
                                    data_uid = {key}
                                    className="btn btn-ctm mr-2 mb-2"
                                    onClick = {this.props.removeAttached}
                            >{this.props.attachUser[key]}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faTimes} /></button>
                            ))}
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-block btn-ctm mt-2">
                                <FontAwesomeIcon icon={faPlus}/>&nbsp;
                                Atribuie utlizator companiei
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

// AssignTable.propTypes = {

// }

export default AssignTable
