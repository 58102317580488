import React from 'react'
// import PropTypes from 'prop-types'

const AddAccount = (props) => {
    const userRef = React.createRef();
    const passRef = React.createRef();
    const checkPassRef = React.createRef();
    const acceslvlRef = React.createRef();
    const firstNameRef = React.createRef();
    const lastNameRef = React.createRef();
    let handleNewUser = (e) => {
        const currentTarget = e.target.name;
        const currentTargetValue = e.target.value;
        props.setNewUser(currentTarget, currentTargetValue);
    }
    return (
        <div className="row justify-content-center">
            <div className="col col-lg-8 card mt-3">
                <h5 className="text-center mt-4 mb-4">
                        Crează cont nou
                </h5>
                <form onSubmit={props.handleUserCreate} className="row justify-content-center" >
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <input type="text" 
                                    ref = {firstNameRef} 
                                    className="form-control" 
                                    id="first_name" 
                                    placeholder="Prenume" 
                                    aria-describedby="userHelp"
                                    autoComplete="off"
                                    onChange = {handleNewUser}
                                    name="first_name"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <input type="text" 
                                    ref = {lastNameRef} 
                                    className="form-control" 
                                    id="last_name" 
                                    placeholder="Nume" 
                                    aria-describedby="userHelp"
                                    autoComplete="off"
                                    onChange = {handleNewUser}
                                    name="last_name"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <input type="text" 
                                    ref = {userRef} 
                                    className="form-control" 
                                    id="user" 
                                    placeholder="Nume login"
                                    aria-describedby="userHelp"
                                    autoComplete="off"
                                    onChange = {handleNewUser}
                                    name="user"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <select ref={acceslvlRef} defaultValue="0" name="account_lvl" className="form-control" onChange = {handleNewUser}>
                                        <option value="0" disabled>Alege nivel de acces</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        {parseFloat(props.userName) > 2?
                                            <option value="3">3</option>
                                        :''}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <input type="password" 
                                    ref = {passRef} 
                                    className="form-control"  
                                    placeholder="Parolă" 
                                    id="password"
                                    autoComplete="off"
                                    name="password"
                                    onChange = {handleNewUser}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="form-group">
                                    <input type="password" 
                                    ref = {checkPassRef} 
                                    className="form-control"  
                                    placeholder="Re-tastează parola" 
                                    id="retype_password"
                                    autoComplete="off"
                                    name="retype_password"
                                    onChange = {handleNewUser}
                                    />
                                </div>
                            </div>
                            <div className="col mb-3">
                                <button className="btn btn-ctm btn-block">Crează cont</button>
                            </div>
                    </form>
            </div>
        </div>
    )
}

// AddAccount.propTypes = {

// }

export default AddAccount
