import React from 'react';
// import PropTypes from 'prop-types';
import './App.css';
import 'bootstrap';
import Login from './components/Login'
import Header from './components/Header'
// import axios from 'axios';
// import Uploader from './components/Uploader'

class App extends React.Component{
  state = {
    userName:{
      uid:'',
      first_name:'',
      last_name:'',
      email:'',
      level:'',
      company:''
    },
    userError:{
      errormsg:''
    }

  }
  
  updateUserState = value => {
    if (value.uid){   
      const userName = {...this.state.userName};
      userName.uid = value.uid;
      userName.first_name = value.first_name;
      userName.last_name = value.last_name;
      userName.email = value.email;
      userName.level = value.level;
      userName.company = value.company;
      this.setState({userName});
      if(userName.uid){
        const home = window.location.href.split('/')[2];
        window.location.href = '//'+home+'/user/'+userName.uid;
      }
    }else if(value.userError){
      const userError = {...this.state.userError};
      userError.errormsg = value.userError;
      this.setState({userError});
    }
  }

  componentDidMount(){
    const localStorageRef = localStorage;
    if (localStorageRef.length>0 && localStorage.getItem('userName')){
      this.updateUserState(JSON.parse(localStorage.getItem('userName'))); 
    }
  }
  componentDidUpdate(){
    localStorage.setItem('userName', JSON.stringify(this.state.userName));
 }
  render(){
    return(
    <div className="container mt-4 min-height-100">
      <Header userName = {this.state.userName ? this.state.userName : false} />
      <Login updateUserState={this.updateUserState}/>
      {this.state.userError.errormsg? <h6 className="text-center">{this.state.userError.errormsg}</h6>:''}
      <div className="row mt-4 justify-content-center">
        <div className="col-md-4 text-center">
          {/* <button className="btn btn-ctm" onClick={this.getSession}>Get Session</button> */}
        </div>
      </div>
    </div>
    )
  }
}
export default App;
