import React from 'react'

const NotFound = props => {
    const wReload = () => {
        const home = window.location.href.split('/')[2];
        window.location.href = '//'+home;
    }
    return (
        <div className="container">
            <div className="row mt-5 justify-content-center">
                <div className="col-md-4 col-md-6 text-center">
                    <h2>Eroare 404  <br/> Pagina nu există</h2>
                    <button onClick={wReload} className="btn btn-ctm">Acasă</button>
                </div>
            </div>
        </div>
    )
}


export default NotFound
