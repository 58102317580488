import React from 'react'
import Logo from './images/spdm-logo-sml.png?v=1'

const Header = props => {
    const userName = props.userName.email ? props.userName : false;
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-6 mt-4 col-md-4 col-lg-3">
                    <img className="img-fluid" alt="SPACE PLANNING DATABASE MANAGER" src={Logo}/>
                    <h6 className="text-center logged">{userName ? 'Logat ca '+ userName.first_name+' '+userName.last_name:''}</h6>
                </div>            
            </div>
        </div>
    )
}

export default Header
