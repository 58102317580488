import React from 'react'
// import PropTypes from 'prop-types'

const AddCompany = props => {
    const companyNameRef = React.createRef();
    // const companyUserRef = React.createRef();
    return (
        <div className="row justify-content-center">
                <div className="col col-lg-8 card mt-3">
                    <h5 className="text-center mt-4 mb-4">
                            Adaugă o companie nouă
                    </h5>
                    <form className="row justify-content-center" onSubmit={props.handleSubmitCompany}>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <input type="text" 
                                ref = {companyNameRef} 
                                className="form-control" 
                                id="company_name" 
                                placeholder="Nume Companie" 
                                aria-describedby="userHelp"
                                autoComplete="off"
                                name="company_name"
                                onChange = {props.handleTypeCompany}
                                />
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="text" 
                                ref = {companyUserRef} 
                                className="form-control" 
                                id="company_user_name" 
                                placeholder="Utilizator (trebuie sa fie creat)" 
                                aria-describedby="userHelp"
                                autoComplete="off"
                                name="company_user_name"
                                onChange = {props.handleTypeCompany}
                                />
                            </div>
                        </div> */}
                        <div className="col-md-12 col-lg-12">
                            <button type="submit" className="btn btn-block btn-ctm mb-4 mt-2">Înregistrează companie</button>
                        </div>
                    </form>
                </div>
            </div>
    )
}

// AddCompany.propTypes = {

// }

export default AddCompany
